<template>
  <div id="signUp">
    <img class="benq_logo" src="../assets/img/benq_logo_2.png" alt="">
    <div class="form">
      <div class="title">用户登录</div>
      <el-form label-position="top" label-width="70px" :model="formData" :rules="rules" ref="uploadForm">
        <el-form-item label="手机号" prop="phone">
          <el-input v-model.number="formData.phone" placeholder="请在此输入您的手机号" class="input-code"></el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="code">
          <el-input v-model="formData.code" placeholder="请在此输入您的手机验证码" class="input-code"></el-input>
          <el-button size="small" type="primary" class="button-inline codebtn" @click="sendCodeEvent"
            :disabled="baseData.sec > 0">{{
              baseData.sec > 0 ? `${baseData.sec}秒后可重发` : "发送验证码"
            }}</el-button>
        </el-form-item>
        <el-form-item class="read-confirm">
          <el-checkbox v-model="formData.checked"></el-checkbox>
          <span class="protocol">我已阅读并同意<a
              href="https://benqgroup.oss-cn-hangzhou.aliyuncs.com/design-competition/design-competition-resources/user.docx">《用户服务协议》</a>和<a
              href="https://benqgroup.oss-cn-hangzhou.aliyuncs.com/design-competition/design-competition-resources/private.docx">《隐私政策》</a></span>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="button-block" @click="formSubmitEvent" style="width: 400px">登录</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tougaoxuzhi">
      <el-dialog v-model="dialogVisible">
        <div class="protocol-doc"></div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { ElMessage } from "element-plus";
import {
  sendVerificationCode,
  loginByCode,
  info
} from "../utils/apis/userLoginApi";


export default {
  name: "signUp",
  setup() {
    // var validateName = (rule, value, callback) => {
    //   let flag = true;
    //   if (/^\s+$/.test(value)) {
    //     flag = false;
    //   }

    //   if (!/^[\u4e00-\u9fa5a-zA-Z\s]{1,16}$/.test(value)) {
    //     flag = false;
    //   }

    //   let match = value.match(/[\u4e00-\u9fa5]/g);

    //   if (!(!match || (match && match.length < 5))) {
    //     flag = false;
    //   }

    //   match = value.match(/[A-Za-z\s]/g);
    //   if (!(!match || (match && match.length < 13))) {
    //     flag = false;
    //   }
    //   if (flag) {
    //     callback();
    //   } else {
    //     callback(new Error("限中文4个字以内，英文12个字母以内"));
    //   }
    // };
    localStorage.setItem('key', 'value')
    const store = useStore()
    const dialogVisible = ref(false);
    const router = useRouter();
    const route = useRoute();
    const formData = reactive({
      code: "",
      phone: "",
      checked: false,
    });
    const rules = reactive({
      phone: [
        { required: true, message: "请输入正确的手机号", trigger: "blur" },
        {
          pattern: /^1[3456789]\d{9}$/,
          message: "请输入正确的手机号",
          trigger: "blur",
        },
      ],
      code: [
        { required: true, message: "请输入6位验证码", trigger: "blur" },
        {
          pattern: /^\d{6}$/,
          message: "请输入6位验证码",
          trigger: "blur",
        },
      ],
    });
    const uploadForm = ref(null);
    const baseData = reactive({
      sec: 0,
      showViewer: "",
    });
    let timer = ref(null);

    /**
     * 倒计时
     */
    function countdownFn() {
      clearInterval(timer);
      timer = setInterval(() => {
        if (baseData.sec <= 0) {
          clearInterval(timer);
        } else {
          baseData.sec--;
        }
      }, 1000);
    }

    //发送验证码
    function sendCodeEvent() {
      //   loginByCode();
      if (baseData.sec > 0) {
        return;
      }
      uploadForm.value.validateField("phone", (err) => {
        if (!err) {
          baseData.sec = 90;
          countdownFn();

          sendVerificationCode({ phone: formData.phone }).then((res) => {
            if (res.data.code == 200) {
              ElMessage({
                message: "验证码已发送",
                type: "success",
                customClass: "myElMessage",
              });
            } else {
              baseData.sec = 0;
              ElMessage({
                message: res.data.message,
                type: "error",
                customClass: "myElMessage",
              });
            }
          });
        }
      });
    }
    //注册
    function formSubmitEvent() {
      if (!formData.checked) {
        ElMessage({
          message: "请同意 《投稿须知》",
          type: "error",
          customClass: "myElMessage",
        });
        return;
      }
      uploadForm.value.validate((vaild) => {
        if (vaild) {
          let params = {
            phone: formData.phone,
            code: formData.code,
          };
          loginByCode(params).then((res) => {
            if (res.data.code == 200) {
              ElMessage({
                message: "报名成功",
                type: "success",
                customClass: "myElMessage",
              });
              localStorage.setItem("token", JSON.stringify(res.data.data));
              store.commit('changeHasLogin', true)
              info().then((res) => {
                console.log(res);
                if (res.data.code === 200) {
                  localStorage.setItem(
                    "userInfo",
                    JSON.stringify(res.data.data)
                  );
                  location.reload()
                  // if (route.query.toMywork != undefined) {
                  //   setTimeout(() => {
                  //     router.push({ path: "/myWorks" });
                  //   }, 2000);
                  // } else {
                  //   setTimeout(() => {
                  //     router.push({ path: "/upLoadFile" });
                  //   }, 2000);
                  // }
                }
              });
            } else if (res.data.message === "该手机号已注册，请登录。") {
              ElMessage({
                message: res.data.message,
                type: "error",
                customClass: "myElMessage",
              });
              setTimeout(() => {
                router.push({ path: "/signUp", query: { toUpload: true } });
              }, 2000);
            } else {
              ElMessage({
                message: res.data.message,
                type: "error",
                customClass: "myElMessage",
              });
            }
          });
        }
      });
    }
    return {
      router,
      formData,
      countdownFn,
      uploadForm,
      baseData,
      rules,
      formSubmitEvent,
      sendCodeEvent,
      dialogVisible,
    };
  },
};
</script>

<style lang="less" scoped>
#signUp {
  padding-top: 70px;
  padding-bottom: 200px;
  margin: 0 auto;
  background-color: #F7F7F7;

  .benq_logo {
    display: block;
    width: 238px;
    height: 47px;
    margin: 53px auto;
  }

  .form {
    width: 472px;
    height: 380px;
    background-color: #F1F1F1;
    box-sizing: border-box;
    border-radius: 6px;
    margin: 0 auto;
    padding-bottom: 200px;

    .title {
      width: 100%;
      height: 50px;
      line-height: 50px;
      font-size: 18px;
      font-weight: bold;
      background-color: #CCCCCC;
      color: #303133;
      text-align: center;
    }

    .el-form-item__label {
      font-size: 14px;
      color: #303133;
    }
  }

  .el-form {
    padding-left: 31px;
  }



  .protocol {
    vertical-align: -1px;
    color: #909399;
    margin-left: 10px;

    i {
      font-style: normal;
      cursor: pointer;
      transition: 0.2s;
    }

    i:hover {
      color: #003588;
    }
  }

  .input-code {
    width: 410px;
    height: 36px;
    margin-right: 0 auto;
    position: relative;
  }

  .codebtn {
    width: 150px;
    height: 40px;
    background-color: transparent;
    border: none;
    color: #333333;
    position: absolute;
    right: 20px;
  }
}

.tougaoxuzhi/deep/ .el-dialog {}

/deep/ .el-form-item {
  margin-bottom: 14px;
}

/deep/ .docx-wrapper {
  padding: 0;
  margin: 0;

  .docx {
    box-shadow: none;
    margin: 0;
    width: auto !important;
    padding: 20px 30px !important;
  }
}
</style>
